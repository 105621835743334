<script lang='ts' setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

const route = inject('route') as typeof import('ziggy-js').default;
const props = defineProps({
    form: Object,
});

const initialValue = ref(props.form?.phone_number ?? '');
const previousValue = ref('');
const isValidating = ref(false);
const isValidated = ref(false);

const validateForm = useForm({
    phone_number: '',
    code: '',
});

const validate = (resend = false) => {
    if (!isValidating.value || resend) {
        validateForm
            .transform((data) => ({
                phone_number: props.form?.phone_number,
            }))
            .post(route('phone-verifications.store'), {
                preserveScroll: true,
                preserveState: true,
                onError: (errors) => (props.form.errors = mapErrors(errors)),
                onSuccess: () => (isValidating.value = true),
            });
    } else {
        validateForm
            .transform((data) => ({
                code: props.form?.phone_number_verification_code,
            }))
            .put(route('phone-verifications.update', { phone_verification: props.form.phone_number }), {
                preserveScroll: true,
                preserveState: true,
                onError: (errors) =>
                    (props.form.errors = mapErrors(errors, { phone_number_verification_code: 'code' })),
                onSuccess: () => (isValidated.value = true),
            });
    }
};

function reset() {
    isValidating.value = false;
    isValidated.value = false;
    props.form.errors.phone_number = [];
    props.form.errors.phone_number_verification_code = [];
}

function handlePhoneInput(arg: any) {
    if (arg !== previousValue.value) {
        reset();
    }
    previousValue.value = arg;
}
</script>

<template>
    <FormKit
        v-model="form.phone_number"
        type="text"
        name="phone_number"
        :suffix-icon="isValidated ? 'check' : ''"
        :label="$t('Mobile number')"
        :errors="form.errors.phone_number"
        :value="form?.phone_number"
        :help="$t('Using a non-Dutch number? Please also add the country code. (example +3234567890)')"
        @input="handlePhoneInput"
    />

    <FormKit
        v-if="isValidating && !isValidated"
        v-model="form.phone_number_verification_code"
        type="text"
        name="phone_number_verification_code"
        :label="$t('Paste or type the code we sent a code to your phone.')"
        :errors="form.errors.phone_number_verification_code"
        :value="form?.phone_number_verification_code"
    />

    <Button
        v-if="!isValidated"
        type="button"
        orange
        :disabled="initialValue === form?.phone_number"
        :processing="validateForm.processing"
        :recently-successful="validateForm.recentlySuccessful"
        :text="$t('Verify {attribute}', { attribute: $t('Phone number') })"
        full-width
        @click="validate()"
    />

    <Button
        v-if="isValidating && !isValidated"
        class="mt-4"
        green
        full-width
        type="button"
        :processing="validateForm.processing"
        :text="$t('Resend {attribute}', { attribute: $t('sms') })"
        @click="validate(true)"
    />
</template>
